import React, { useState, useRef, useEffect } from 'react';
import ReactSlider from 'react-slider';
import './App.css';

function App() {
  const playerRef = useRef(null);
  const [videoURL, setVideoURL] = useState('');
  const [videoId, setVideoId] = useState('EEip7_ZiJxI'); // Default video ID
  const [loopPoints, setLoopPoints] = useState([0, 100]); // Store both start and end in one array
  const [videoDuration, setVideoDuration] = useState(100); // Default duration to avoid undefined errors

  useEffect(() => {
    const loadYouTubeAPI = () => {
      if (!window.YT) {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      }

      window.onYouTubeIframeAPIReady = () => {
        playerRef.current = new window.YT.Player('ytplayer', {
          videoId: videoId,
          events: {
            onReady: onPlayerReady,
            onStateChange: updateCurrentTime, // Update current time on state change
          },
        });
      };
    };

    loadYouTubeAPI();
  }, [videoId]); // Reload the video when videoId changes

  const onPlayerReady = () => {
    if (playerRef.current) {
      const duration = playerRef.current.getDuration();
      setVideoDuration(duration); // Set the video duration when the player is ready
      setLoopPoints([0, duration]); // Ensure point B is maxed out at the video duration
    }
  };

  useEffect(() => {
    const checkLoop = () => {
      if (
        playerRef.current &&
        loopPoints[0] !== null &&
        loopPoints[1] !== null &&
        playerRef.current.getCurrentTime() >= loopPoints[1]
      ) {
        playerRef.current.seekTo(loopPoints[0]);
      }
    };

    const interval = setInterval(checkLoop, 1000);
    return () => clearInterval(interval);
  }, [loopPoints]);

  const handleURLChange = (e) => {
    setVideoURL(e.target.value);
  };

  const handleLoadVideo = () => {
    const id = extractVideoId(videoURL);
    if (id) {
      setVideoId(id);
      setLoopPoints([0, videoDuration]); // Reset loop points to default start and end
    } else {
      alert('Invalid YouTube URL');
    }
  };

  const extractVideoId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|watch\?v=|\/embed\/)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  const setLoopStartHandler = () => {
    if (playerRef.current) {
      const startTime = playerRef.current.getCurrentTime();
      setLoopPoints([startTime, loopPoints[1]]);
    }
  };

  const setLoopEndHandler = () => {
    if (playerRef.current) {
      const endTime = playerRef.current.getCurrentTime();
      setLoopPoints([loopPoints[0], endTime]);
    }
  };

  const resetLoop = () => {
    setLoopPoints([0, videoDuration]);
    console.log('Loop points cleared');
  };

  const updateCurrentTime = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      console.log('Current time:', currentTime);
    }
  };

  const handleSliderChange = (values) => {
    setLoopPoints(values);
    if (playerRef.current) {
      playerRef.current.seekTo(values[0]); // Optionally, you can seek the video to the start handle value
    }
  };

  // Helper function to format seconds into "minute:second" format
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <div className="App">
      <h1>VideoLooper.com</h1>
      <div className="input-section">
        <input
          type="text"
          placeholder="Paste YouTube URL here"
          value={videoURL}
          onChange={handleURLChange}
        />
        <button onClick={handleLoadVideo}>Load Video</button>
      </div>

      <div className="video-section">
        <iframe
          id="ytplayer"
          type="text/html"
          width="640"
          height="360"
          src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1`}
          frameBorder="0"
        ></iframe>
      </div>

      <div className="controls">
        <button onClick={setLoopStartHandler}>Set Loop Start</button>
        <button onClick={setLoopEndHandler}>Set Loop End</button>
        <button onClick={resetLoop}>Reset Loop</button>
        <button onClick={() => playerRef.current?.seekTo(0)}>Restart Video</button>
      </div>

      <div className="slider-section">
        <label>Loop Start & End:</label>
        <ReactSlider
          className="horizontal-slider"
          thumbClassName={(index) =>
            index === 0 ? 'thumb thumb-start' : 'thumb thumb-end'
          }
          trackClassName="track"
          min={0}
          max={videoDuration}
          step={0.1}
          value={loopPoints}
          onChange={handleSliderChange}
          ariaLabel={['Loop Start', 'Loop End']}
          pearling
          renderTrack={(props, state) => {
            const index = state.index;
            let background = '#ddd';
            if (index === 1) {
              background = '#007bff';
            }
            return <div {...props} style={{ ...props.style, background }} />;
          }}
        />
        <div>
          Start: {formatTime(loopPoints[0])} , End: {formatTime(loopPoints[1])}
        </div>
      </div>
      <footer>Made with ❤️ by LafayetteWCS.com</footer>
    </div>
  );
}

export default App;